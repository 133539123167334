module.exports = () => {
  document.querySelectorAll(".zeitfrei-mail").forEach((el) => {
    const localPart = el.dataset.localPart;
    const email = `${localPart}@zeitfrei.info`;
    const link = document.createElement("a");
    link.setAttribute("href", `mailto:${email}`);
    link.appendChild(document.createTextNode(email));
    el.appendChild(link);
  });
};
