const xss = require("xss");
module.exports = () => {
  document.querySelectorAll("form").forEach((e) => {
    const form = e;
    if (!form) {
      return;
    }

    const alert = (type, name) => {
      const danger = `Entschuldige bitte ${xss(
        name
      )}! Leider ist hier etwas schiefgegangen. Bitte später erneut versuchen!`;
      const className = "hidden";
      const successWrapper = form.querySelector(".success");
      successWrapper.classList.remove(className);
      const errorElement = successWrapper.querySelector(".alert-error");
      errorElement.querySelector("b").innerText = danger;
      const successElement = successWrapper.querySelector(".alert-success");

      if (type === "danger") {
        successElement.classList.add(className);
        errorElement.classList.remove(className);
      } else {
        successElement.classList.remove(className);
        errorElement.classList.add(className);
      }
    };

    form.addEventListener("submit", async (e) => {
      e.preventDefault();
      const url = form.getAttribute("action");
      const object = {};
      const formData = new FormData(form);
      formData.forEach(
        (value, key) => (object[key.replace(/.*-/, "")] = value)
      );
      const body = JSON.stringify(object);

      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body,
      });
      if (res.ok) {
        alert("success");
      } else {
        alert("danger", object.name);
      }
      form.reset();
    });

    document.addEventListener("click", (e) => {
      const target = e.target;

      if (target.classList.contains("close")) {
        target.closest(".alert").remove();
      }
    });
  });
};
