require("regenerator-runtime");

const form = require("./form");
const mail = require("./mail");

window.addEventListener("DOMContentLoaded", () => {
  form();
  mail();

  var navMenuDiv = document.getElementById("nav-content");
  var navMenu = document.getElementById("nav-toggle");
  document.onclick = check;
  function check(e) {
    var target = (e && e.target) || (event && event.srcElement);

    if (!checkParent(target, navMenuDiv)) {
      if (checkParent(target, navMenu)) {
        // click on the link
        if (navMenuDiv.classList.contains("hidden")) {
          navMenuDiv.classList.remove("hidden");
        } else {
          navMenuDiv.classList.add("hidden");
        }
      } else {
        // click both outside link and outside menu, hide menu
        navMenuDiv.classList.add("hidden");
      }
    }
  }
  function checkParent(t, elm) {
    while (t.parentNode) {
      if (t == elm) {
        return true;
      }
      t = t.parentNode;
    }
    return false;
  }
});
